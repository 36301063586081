<template>
  <v-row class="fill-height">
    <v-col cols="12" class="mx-5 mt-5 font-weight-bold text-h6">
      Hi, {{ userProfile.data().name }}
    </v-col>
    <v-col cols="12">
      <kiosk-list></kiosk-list>
    </v-col>
    <!-- <v-col cols="12">
      <v-btn @click="$store.dispatch('migrateQnaIntoSession')">Migrate QNA</v-btn>
    </v-col> -->
  </v-row>
</template>

<script>
import KioskList from '../components/dashboard/admin/homeUI/kioskList.vue';
import { mapGetters } from 'vuex'
export default {
  name: 'home',
  components: {
    KioskList
  },
  computed: {
    ...mapGetters(["userProfile"])
  }
}
</script>